import { useEffect, useCallback, ReactElement } from 'react'
import { useToggle, useAsyncFn } from 'react-use'
import { map } from 'awaity/esm'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { useRecoilValue } from 'recoil'

// constants
import { FILE_TYPES } from 'constants/common'
import { assetsProfilesKeyByProfileSelector } from 'recoilStore/assetsStore'
import { THEMES } from 'constants/colour'

// utils
import { useTimezone } from 'hooks'
import { getLayerFilteredData } from 'helpers/filter'
import { getDatasetOptionByDatasetNameAndCatalogId } from 'helpers/unipipe'
import {
  convertGeojsonToTableData,
  getDataMetadata,
} from 'components/map/controls/MapDataDownloader/util'
import { isSelectedWidgetActive } from 'helpers/widget'
import { getExcelSheetName } from 'helpers/utils'
import { useStateValue, useMapStateValue } from 'contexts'
import { isAssetLayer, getAssetLayerProfileId } from 'helpers/map'

// components
import { Table, IconButton } from 'components/common'

// types
import type { MapLayer, MapLayerData } from 'types/map'

import { Payload, PropertiesMetadata } from 'types/common'
import _ from 'lodash'
import scss from './index.module.scss'

type TableDataProps = {
  data: MapLayerData
  id: string
  layer: MapLayer
  metadata: PropertiesMetadata
  options: Payload
  title: string
}

const MapDataTabs = ({
  id,
  data = [],
}: {
  id: string
  data: TableDataProps[]
}) => {
  const { isTimelineExpanded } = useMapStateValue()

  return (
    <Tabs
      key={id}
      selectedTabClassName={scss.selectedTab}
      forceRenderTabPanel
      className='h-100'
    >
      <TabList>
        {data.map(({ layer }) => (
          <Tab className={scss.tab} key={`tab-${layer.id}`}>
            {layer.name}
          </Tab>
        ))}
      </TabList>
      {data.map(passProps => {
        const height = isTimelineExpanded
          ? 'calc(100vh - 350px)'
          : 'calc(100vh - 200px)'

        const props = _.merge(passProps, { options: { height } })

        return (
          <TabPanel key={`tabPanel-${props.id}`}>
            <Table {...props} theme={THEMES.light} />
          </TabPanel>
        )
      })}
    </Tabs>
  )
}

const DataDownloaderWidget = ({
  widgetId,
  title,
  layers,
  size = 16,
  iconClassName,
  downloadFileTypes,
}: {
  widgetId: string
  title: string
  layers: MapLayer[]
  size: number
  iconClassName: string
  downloadFileTypes: string[]
}): ReactElement => {
  const [isShowing, toggleIsShowing] = useToggle(false)

  const {
    state: {
      unipipeState: { loading },
    },
    selectors: {
      unipipeSelectors: { mapEligibleDatasetOptions },
    },
  } = useStateValue()

  const assetProfileKeyByProfile = useRecoilValue(
    assetsProfilesKeyByProfileSelector
  )

  const { timezone } = useTimezone()

  const {
    getLayersFilteredData,
    viewportBounds,
    isSpatialFilterEnabled,
    setMaxWidgetSelected,
    maxWidgetSelected,
  } = useMapStateValue()

  const toggleDataDownloader = useCallback(
    (data, keepDownloader) => {
      const getDownloader = () => ({
        Component: MapDataTabs,
        widgetProps: {
          data,
          id: widgetId,
        },
      })

      setMaxWidgetSelected(oldMaxWidgetSelected => {
        return !keepDownloader &&
          isSelectedWidgetActive(widgetId, oldMaxWidgetSelected)
          ? null
          : getDownloader()
      })
    },
    [setMaxWidgetSelected, widgetId]
  )

  const [dataState, getDownloaderData] = useAsyncFn(
    async keepDownloader => {
      if (loading) return undefined

      const layersFilteredData = getLayersFilteredData()

      const getTableData = (layer: MapLayer) => {
        const layerData = getLayerFilteredData({
          layer,
          layersFilteredData,
          bbox: viewportBounds,
          isSpatialFilterEnabled,
        })

        return convertGeojsonToTableData(layerData)
      }

      const getLayerPropertyOptions = (layer: MapLayer): PropertiesMetadata => {
        const { dataset, catalogId } = layer

        if (isAssetLayer(layer)) {
          return _.get(
            assetProfileKeyByProfile[getAssetLayerProfileId(layer)],
            'properties'
          )
        }

        const datasetOption = getDatasetOptionByDatasetNameAndCatalogId(
          mapEligibleDatasetOptions,
          dataset,
          catalogId
        )
        return datasetOption?.properties
      }

      const getTableId = (id: string) => `table-${id}`

      const getTableSheets = (layer: MapLayer) =>
        layers.reduce((acc, { id, name }, index) => {
          const value = name === layer.name ? true : `#${getTableId(id)}`
          const sheetName = getExcelSheetName(name)
          const validSheetName = acc[sheetName]
            ? `${sheetName.length > 1 ? sheetName.slice(0, -1) : sheetName}${
                index + 1
              }`
            : sheetName

          return {
            ...acc,
            [validSheetName]: value,
          }
        }, {})

      const result = await map(layers, async (layer: MapLayer) => {
        const propertyOptions = getLayerPropertyOptions(layer)
        const data = getTableData(layer)
        const sheets = getTableSheets(layer)
        const id = getTableId(layer.id)
        const metadata = getDataMetadata(propertyOptions)

        return {
          id,
          title,
          options: {
            downloadConfigs: { sheets },
            downloadFileTypes,
            renderVerticalBuffer: 300,
          },
          data,
          metadata,
          layer,
        }
      })

      toggleDataDownloader(result, keepDownloader)

      return result
    },
    [loading, layers, timezone, viewportBounds]
  )

  useEffect(() => {
    if (!isShowing || !isSelectedWidgetActive(widgetId, maxWidgetSelected))
      return

    getDownloaderData(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLayersFilteredData()])

  const isLoading = loading || dataState.loading

  return (
    <IconButton
      className={`${iconClassName} ${isLoading ? 'fa-spin' : ''}`}
      icon={isLoading ? 'FaSpinner' : 'BsTable'}
      size={size}
      {...(!isLoading && {
        onClick: () => {
          const shouldShowTable = !isShowing || !maxWidgetSelected
          if (shouldShowTable) {
            getDownloaderData()
          } else {
            setMaxWidgetSelected(null)
          }
          toggleIsShowing(shouldShowTable)
        },
      })}
    />
  )
}

const MapDataDownloader = (props: Payload): ReactElement => {
  const {
    map: { id: mapId, name: mapName, layers },
  } = useMapStateValue()

  return (
    <DataDownloaderWidget
      {...props}
      key={mapId}
      widgetId={mapId}
      title={mapName}
      layers={layers}
      downloadFileTypes={[FILE_TYPES.xlsx]}
    />
  )
}

export const LayerDataDownloader = ({
  layer,
  ...rest
}: {
  layer: MapLayer
  rest: {
    iconClassName: string
    size: number
  }
}): ReactElement => {
  // only available for dataset layer at the moment
  const { id: layerId, name, dataset } = layer

  return (
    <DataDownloaderWidget
      {...rest}
      key={layerId}
      widgetId={layerId}
      title={`${name}: ${dataset}`}
      layers={[layer]}
    />
  )
}

export default MapDataDownloader
