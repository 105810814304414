import type { PropertiesMetadata } from 'types/common'

const FIND_PROPERTIES_IN_TEXT_REGEX = /(\{.*?\})/g
const CHECK_PROPERTY_STRING_REGEX = /^\{.*?\}/

const getPropPathRegex = (propPrefix: string) =>
  new RegExp(`{${propPrefix}(.*?)}`)

/** Returns HTML string with the <span> of mention type */
export const generateMentionHtml = ({
  value,
  label,
}: {
  value: string
  label: string
}): string =>
  `<span data-type="mention" class="mention" data-id="${value}" data-label="${label}">${label}</span>`

/** Recursively iterates over the nodes and transforms elements if necessary */
const processElement = (el: HTMLElement) => {
  // Since the editor's "text background-color" plugin works only with "<mark>",
  // need to replace tags
  if (el.style?.backgroundColor && el.nodeName !== 'MARK') {
    const mark = document.createElement('mark')
    // eslint-disable-next-line no-restricted-syntax
    for (const attr of el.attributes) {
      mark.setAttributeNS(null, attr.name, attr.value)
    }
    mark.innerHTML = el.innerHTML
    el.replaceWith(mark)
  }

  const children: HTMLElement[] = Array.from(el.childNodes || [])
    .map(element => processElement(element as HTMLElement))
    .flat()

  return children
}

/** Prepares external HTML to be correctly displayed in the editor */
const prepareHtmlForEditor = (
  html: string,
  options: PropertiesMetadata = [],
  propPrefix = 'properties.'
): string => {
  const parsed = new DOMParser().parseFromString(html, 'text/html')
  processElement(parsed.body)
  const outer = parsed.body.innerHTML
  const splittedTextArr = outer
    .split(FIND_PROPERTIES_IN_TEXT_REGEX)
    .filter(Boolean)
  const pathRegex = getPropPathRegex(propPrefix)

  // Replace {properties.propName} with <span data-type="mention">propName</span>
  const result = splittedTextArr
    .map(content => {
      if (CHECK_PROPERTY_STRING_REGEX.test(content)) {
        const propValue = content.match(pathRegex)?.[1] || ''
        const { value, label } = options.find(
          option => option.value === propValue
        ) || { value: propValue, label: propValue }

        return generateMentionHtml({ value, label })
      }
      return content
    })
    .join('')

  return result
}

export default prepareHtmlForEditor
