import moment from 'moment-timezone'
import _ from 'lodash'

import type {
  DetectionData,
  EmissionObservationData,
} from 'app/MissionControlMethaneSolution/types/detection'
import type { UtcISOString } from 'types/datetime'
import type { EmissionObservation } from 'app/MissionControlMethaneSolution/types/graphql'

// constants
import { EMISSION_OBSERVATION_ATTRIBUTION_TYPES } from 'app/MissionControlMethaneSolution/constants/emissionObservation'
import { roundToDecimalPlaces } from './common'
import { DETECTION_SOURCES } from '../constants/common'

export const getAttributionName = ({
  sourceAttribution,
  site,
  equipmentDescription,
}: Pick<DetectionData, 'sourceAttribution' | 'site'> & {
  equipmentDescription?: string | null
}): string | null | undefined => {
  if (!sourceAttribution) return ''

  const isAttributedToEquipment =
    sourceAttribution.attributionType ===
    EMISSION_OBSERVATION_ATTRIBUTION_TYPES.EQUIPMENT

  return isAttributedToEquipment ? equipmentDescription : site.name
}

export const getEmissionsRateFromTotalVolume = (
  data: {
    endTime: UtcISOString
    startTime: UtcISOString
    totalVolume: number
  }[],
  start: UtcISOString,
  end: UtcISOString
): number | null => {
  if (_.isEmpty(data) || !start || !end) return null

  const totalVolumeSum = _.sumBy(data, 'totalVolume')
  const diffHours = moment(end).diff(moment(start), 'hours', true)
  if (diffHours === 0) return null

  return roundToDecimalPlaces(totalVolumeSum / diffHours, 4)
}

export const isVFBEvent = (
  emissionObservation: EmissionObservation
): boolean => {
  if (!emissionObservation) return false

  return (
    !!emissionObservation.ventingEventType ||
    _.startsWith(emissionObservation.shortId, 'VFB')
  )
}

const isMatchingDetectionSource = (
  emissionObservation: EmissionObservationData,
  source: string
): boolean => {
  return !!emissionObservation && emissionObservation.detectionSource === source
}

export const isQubeData = (
  emissionObservation: EmissionObservationData
): boolean => {
  return isMatchingDetectionSource(emissionObservation, 'Qube')
}

export const isKuvaData = (
  emissionObservation: EmissionObservationData
): boolean => {
  return isMatchingDetectionSource(emissionObservation, 'Kuva Systems')
}

export const isLongPathData = (
  emissionObservation: EmissionObservationData
): boolean => {
  return isMatchingDetectionSource(
    emissionObservation,
    DETECTION_SOURCES.Longpath
  )
}
