import _ from 'lodash'

// types
import type { TextTemplate } from 'types/common'

// constants
import { EDITOR_PROPERTIES_REGEXP } from 'constants/strings'

type Property = { [key: string]: string }

export const replaceWhiteFontColorForPrintable = (
  template: TextTemplate
): string => {
  return template.replace(
    /color: (rgb\(255, 255, 255\)|#ffffff|#fff|white)/gi,
    'color: #000000'
  )
}

export const getCompiledText = ({
  template,
  data,
  isPrintable,
  propertyPrefix = 'properties',
  defaultNoData = '[no data]',
  interpolate = EDITOR_PROPERTIES_REGEXP,
  displayDefaultNoData = false,
}: {
  template: TextTemplate
  data: { properties: Property }
  isPrintable?: boolean
  propertyPrefix?: string
  defaultNoData?: string
  interpolate?: RegExp
  displayDefaultNoData?: boolean
}): string => {
  let newPropertiesData = data

  if (displayDefaultNoData) {
    const matchedProperties = template.match(interpolate) || []

    const templatePropertyObj = matchedProperties.reduce((acc, cur) => {
      const key = cur.replace(`{${propertyPrefix}.`, '').replace('}', '')
      return { ...acc, [key]: defaultNoData }
    }, {})

    newPropertiesData = _.defaultsDeep({}, data, {
      [propertyPrefix]: templatePropertyObj,
    })
  }

  const newTemplate = isPrintable
    ? replaceWhiteFontColorForPrintable(template)
    : template

  return _.template(newTemplate, { interpolate })(newPropertiesData)
}
