// libraries
import _ from 'lodash'
import wellknown from 'wellknown'

import type { PropertiesMetadata } from 'types/common'
import { MapLayerData } from 'types/map'

const GEOMETRY_KEY = 'geometry'

export const getDataMetadata = (
  propertyOptions: PropertiesMetadata
): PropertiesMetadata => [
  ...propertyOptions,
  {
    label: 'Geometry',
    type: 'string',
    value: GEOMETRY_KEY,
  },
]

export const convertGeojsonToTableData = (
  geojsonRows: MapLayerData
): MapLayerData => {
  return _.map(geojsonRows, row => {
    return {
      ...row.properties,
      [GEOMETRY_KEY]: wellknown.stringify(row.geometry),
    }
  })
}
